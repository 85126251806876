import React, {useMemo, useState} from 'react'
import MaterialReactTable from "material-react-table";
import {useTranslation} from "react-i18next";

const GenericTable = (props) => {
    const {t} = useTranslation();
    const {
        outputColumns,
        tableData = [],
        initialState = {},
        enableColumnActions = true,
        enableTopToolbar = true,
        expand = false,
        enablePagination = tableData.length > 10,
        rowCount = tableData.length  !== 0 ? tableData.length : 10,
        state,
        enableStickyHeader = true,
        allRowsAreExpanded = false,
        enableColumnResizing = false,
        enableFullScreenToggle = true,
        tableExpandPadding = 4,
        enableGlobalSearchBar = true,
        enableColumnFilters = true,
        enableHiding = false,
        hideAllToolbar = false,
        headerBgColor = '#7F8C8D',
        headerTextColor = 'white',
        rowBgColor = '#white'
    } = props

    const DEFAULT_PAGE_SIZE = 10;

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    });

    const columns = useMemo(() => outputColumns.data, [outputColumns.data]);

    return (
        <MaterialReactTable
            columns={columns}
            data={tableData}
            state={{
                ...state,
                pagination
            }}
            enableStickyHeader={enableStickyHeader}
            enableStickyFooter
            enableColumnActions={enableColumnActions}
            enableTopToolbar={enableTopToolbar}
            renderDetailPanel={expand}
            initialState={{
                ...initialState,
                columnVisibility: outputColumns.visibility,
                expanded: allRowsAreExpanded,
                showGlobalFilter: true
            }}
            muiSearchTextFieldProps={{
                placeholder: tableData.length > 0 ?
                    t("generic-table.table-search-placeholder", {rowCount: tableData.length}):
                    t("generic-table.no-records-to-search"),
                sx: {minWidth: '300px'},
                variant: 'standard',
            }}
            muiTableDetailPanelProps={{
                sx: {
                    padding: tableExpandPadding
                },
            }}
            muiTopToolbarProps={{
                sx: hideAllToolbar ? { minHeight: 0, height: 0, overflow: 'hidden', padding: 0 } : {}, // Removes toolbar spacing
            }}
            muiTableHeadCellProps={{
                sx: {
                    backgroundColor: headerBgColor, // Change header background color
                    color: headerTextColor, // Change text color
                    fontWeight: 'bold', // Make text bold
                },
            }}
            muiTableBodyRowProps={{
                sx: {
                    backgroundColor: rowBgColor, // Change row background color
                    '&:hover': { backgroundColor: '#e0e0e0' }, // Hover effect for rows
                },
            }}
            enablePagination={enablePagination}
            onPaginationChange={setPagination}
            rowCount={rowCount}
            enableGlobalFilterModes
            enableFullScreenToggle={enableFullScreenToggle}
            positionGlobalFilter="left"
            enableGlobalFilter={enableGlobalSearchBar} //general searchBar for all columns
            enableDensityToggle={false}
            enableColumnResizing={enableColumnResizing}
            enableColumnFilters={enableColumnFilters}
            enableHiding={enableHiding}

        />
    )
}

export default GenericTable