import React from 'react';
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import Card from "../../common/card/Card";
import ReportsFiltersUI from "../ProductPerformance/ReportsFiltersUI/ReportsFiltersUI";
import {getBrandsInfluencersReports} from "../../services/brandsInfluencerReports";
import {formatTurkishLira, startEndDateInitial} from "../../common/utils";
import GenericTable from "../../common/table/GenericTable";
import {Avatar} from "@mui/material";
import styles from "./BrandsInfluencersReports.module.scss";
import ExportDropdown from "../../common/exportDropdown/ExportDropdown";
import {EXPORT_FILE_TYPES} from "../AdvertiserManagement/CreateEditPaidProject/CreateEditPaidProject";
import {DOWNLOAD_BRANDS_INFLUENCERS_CONVERSIONS_EXCEL_DATA} from "../../common/constants/routes";
import ConversionsResultsUI from "../ConversionsResultsUI/ConversionsResultsUI";

const initialResponse = {
    totalSales: 0,
    totalQuantity: 0,
    appInstallations: 0,
    iosPurchases: 0,
    androidPurchases: 0,
    isFirstPurchase: 0,
    brands: [],
    firstPurchaseAmount: 0
}


const BrandsInfluencersReports = () => {

    const [reports, setReports] = React.useState(initialResponse);
    const [startEndDateValue, setStartEndDateValue] = React.useState(startEndDateInitial);

    const productColumns = {};
    productColumns.visibility = {}
    productColumns.data = [
        {
            accessorKey: 'image',
            header: "Image",
            Cell: (cell) => (
                <Avatar sx={{width: 100, height: 100, mt: -1}} alt={cell.row.original.title}
                        src={cell.row.original.image}/>
            )
        },
        {
            accessorKey: 'title',
            header: "Title",
            size: 400,
            Cell: (cell) => (
                <a href={cell.row.original.link ? "https://www.karaca.com" + cell.row.original.link : "https://www.karaca.com"}
                   target={"_blank"} rel="noreferrer">  {cell.row.original.title}</a>
            )
        },
        {
            accessorKey: 'product_id',
            header: "ProductId",
        },
        {
            accessorKey: 'quantity',
            header: "Quantity",
        },
        {
            accessorKey: 'price',
            header: "Price",
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.price)}</div>
            )
        }
    ];

    const brandColumns = {};
    brandColumns.visibility = {}
    brandColumns.data = [
        {
            accessorKey: 'brand_name',
            header: "BrandName",
        },
        {
            accessorKey: 'total_sales',
            header: "Total Sales",
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.total_sales)}</div>
            )
        },

        {
            accessorKey: 'total_quantity',
            header: "Total Quantity",
        }
    ];

    const affiliateColumns = {};
    affiliateColumns.visibility = {}
    affiliateColumns.data = [
        {
            accessorKey: 'affiliate_company',
            header: "Influencer Name",
        },
        {
            accessorKey: 'total_sales',
            header: "Total Sales",
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.total_sales)}</div>
            )
        },
        {
            accessorKey: 'total_quantity',
            header: "Total Quantity",
        }
    ];

    const handleReportGeneration = async (payload) => {

        const response = await getBrandsInfluencersReports(payload);
        setStartEndDateValue({
            startDate: payload.startDate,
            endDate: payload.endDate
        })
        if (!!response.data) {
            const responseObject = response.data;
            setReports(responseObject);
        }

    }

    const handleDownloadFile = async (fileType) => {
        if (!startEndDateValue || !startEndDateValue.startDate || !startEndDateValue.endDate) return;
        const startDate = startEndDateValue.startDate;
        const endDate = startEndDateValue.endDate;
        switch (fileType) {
            case EXPORT_FILE_TYPES.excel:
                window.open(`${(process.env.REACT_APP_DASHBOARD_API ?? "http://localhost:4000") + DOWNLOAD_BRANDS_INFLUENCERS_CONVERSIONS_EXCEL_DATA}?startDate=${startDate}&endDate=${endDate}&type=excel`, "_blank");
                break;
            case EXPORT_FILE_TYPES.csv:
                window.open(`${(process.env.REACT_APP_DASHBOARD_API ?? "http://localhost:4000") + DOWNLOAD_BRANDS_INFLUENCERS_CONVERSIONS_EXCEL_DATA}?startDate=${startDate}&endDate=${endDate}&type=csv`, "_blank");
                break;
            default:
                break
        }
    }

    const expandProducts = (cell) => {
        return (
            <div>
                <GenericTable
                    tableData={cell.row.original.products}
                    outputColumns={productColumns}
                    tableExpandPadding={0}
                    enableFullScreenToggle={false}
                    enableGlobalSearchBar={false}
                    enableColumnFilters={false}
                    enableHiding={false}
                    hideAllToolbar={true}
                    headerBgColor={"#E74C3C"}
                />
            </div>
        )
    }

    const expandInfluencers = (cell) => {
        return (
            <div>
                <GenericTable
                    tableData={cell.row.original.affiliate_companies}
                    outputColumns={affiliateColumns}
                    expand={expandProducts}
                    tableExpandPadding={0}
                    enableFullScreenToggle={false}
                    enableGlobalSearchBar={false}
                    enableColumnFilters={false}
                    enableHiding={false}
                    hideAllToolbar={true}
                    headerBgColor={"#8E44AD"}
                    headerTextColor={"white"}
                    rowBgColor={"#f1f1f1"}
                />
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <BreadcrumbsBar/>
            <Card title={"Brands Influencers Reports"}>
                <ReportsFiltersUI onGenerateButtonClicked={handleReportGeneration}/>
                {reports.brands && reports.brands.length > 0 &&
                    <div>
                        <ConversionsResultsUI reports={reports}/>
                        <div className={styles.buttonHolder}>
                            <ExportDropdown handleCb={handleDownloadFile}/>
                        </div>
                        <GenericTable
                            outputColumns={brandColumns}
                            tableData={reports.brands}
                            expand={expandInfluencers}
                            tableExpandPadding={0}/>
                    </div>
                }
            </Card>
        </div>
    );
};

export default BrandsInfluencersReports;
