import {clientGetWrapper, clientPostWrapper} from "../api/Api";
import {
    ADD_DASHBOARD_USER, ADD_NEW_KARACA_USER,
    ADD_NEW_LCW_USER, DELETE_APP_USER,
    GET_ALL_HAS_OFFER_USERS,
    GET_SELECTED_HAS_OFFER_USER,
    GET_SELECTED_HAS_OFFER_USER_BANK_DETAILS, LOGIN_APP_USER, MYSQL_USER_SETTINGS_UPDATE,
    UPDATE_SELECTED_HAS_OFFER_USER_ADDITIONAL_DATA,
    UPDATE_SELECTED_HAS_OFFER_USER_BANK_DATA,
    UPDATE_SELECTED_HAS_OFFER_USER_DATA
} from "../common/constants/routes";


export const getAllHasOfferUsers = () => {
    return clientGetWrapper(GET_ALL_HAS_OFFER_USERS);
}

export const getSelectedHasOfferUser = (email) => {
    return clientPostWrapper(GET_SELECTED_HAS_OFFER_USER,{email:email});
}

export const updateHasOfferUserSettings = (payload) => {
    return clientPostWrapper(UPDATE_SELECTED_HAS_OFFER_USER_DATA,payload);
}

export const updateUserGeneralSettings = (payload) => {
    return clientPostWrapper(MYSQL_USER_SETTINGS_UPDATE,payload);

}

export const addNewUser = (payload) => {
    return clientPostWrapper(ADD_DASHBOARD_USER, payload);
}
export const getHasOfferUserBankSettings = (email) => {
    return clientGetWrapper(GET_SELECTED_HAS_OFFER_USER_BANK_DETAILS+`?email=`+email);
}

export const updateHasOfferUserBankSettings = (payload) => {
    return clientPostWrapper(UPDATE_SELECTED_HAS_OFFER_USER_BANK_DATA,{payload});
}


export const updateHasOfferUserAdditionalData = (payload) => {
    return clientPostWrapper(UPDATE_SELECTED_HAS_OFFER_USER_ADDITIONAL_DATA,
        payload);
}

export const addNewLCWUser = (payload) => {
    return clientPostWrapper(ADD_NEW_LCW_USER, {payload:payload});
}


export const deleteAppUser = (payload) => {
    return clientPostWrapper(DELETE_APP_USER, payload);
}

export const loginInfluioUserApp = (payload) => {
    return clientPostWrapper(LOGIN_APP_USER, payload);
}


export const addNewKaracaUser = (payload) => {
    return clientPostWrapper(ADD_NEW_KARACA_USER, {payload:payload});
}



