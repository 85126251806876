import React, {useState} from 'react'
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import styles from "./ProductPerformance.module.scss";
//xml file reader
import {t} from "i18next";
import useToast from "../../common/hooks/useToast";
import Toast from "../../common/toast/Toast";
import Card from "../../common/card/Card";
import {getConversionReport} from "../../services/productPerformanceService";
import {DOWNLOAD_OFFER_PRODUCTS_EXCEL_DATA} from "../../common/constants/routes";
import ExportDropdown from "../../common/exportDropdown/ExportDropdown";
import {EXPORT_FILE_TYPES} from "../AdvertiserManagement/CreateEditPaidProject/CreateEditPaidProject";
import ReportsFiltersUI from "./ReportsFiltersUI/ReportsFiltersUI";
import ConversionsResultsUI from "../ConversionsResultsUI/ConversionsResultsUI";
import GenericTable from "../../common/table/GenericTable";
import {formatTurkishLira} from "../../common/utils";
import {Avatar} from "@mui/material";


const ProductPerformance = () => {
    const {isToastOpen, toggleToast, toastContent} = useToast();
    const [conversionResult, setConversionResult] = useState(null);
    const [payload, setPayload] = useState(null);
    const resultColumns = {}
    resultColumns.visibility = {}
    resultColumns.data =  [
        {
            accessorKey: 'image',
            header: "Image",
            Cell: (cell) => (
                <Avatar sx={{width: 100, height: 100, mt: -1}} alt={cell.row.original.title} src={cell.row.original.image}/>
            )
        },
        {
            accessorKey: 'title',
            header: "Title",
            Cell: (cell) => (
                <a href={cell.row.original.link ? "https://www.karaca.com" + cell.row.original.link : "https://www.karaca.com"}
                   target={"_blank"} rel="noreferrer">  {cell.row.original.title}</a>
            )
        },
        {
            accessorKey: 'quantity',
            header: "Quantity",
        },
        {
            accessorKey: 'brand',
            header: "Brand"
        },
        {
            accessorKey: 'category_name',
            header: "Category",
        },
        {
            accessorKey: 'price',
            header: "Price",
            Cell: (cell) => (
                <span>{formatTurkishLira.format(cell.row.original.price)}</span>
            )
        },
        {
            accessorKey: 'sale_amount',
            header: "Total Sale",
            Cell: (cell) => (
                <span>{formatTurkishLira.format(cell.row.original.price * cell.row.original.quantity)}</span>
            )
        }
    ];

    const productsColumns =  [
        {
            accessorKey: 'image',
            header: "Image",
            Cell: (cell) => (
                <Avatar sx={{width: 100, height: 100, mt: -1}} alt={cell.row.original.title} src={cell.row.original.image}/>
            )
        },
        {
            accessorKey: 'title',
            header: "Title",
            Cell: (cell) => (
                <a href={cell.row.original.link ? "https://www.karaca.com" + cell.row.original.link : "https://www.karaca.com"}
                   target={"_blank"} rel="noreferrer">  {cell.row.original.title}</a>
            )
        },
        {
            accessorKey: 'quantity',
            header: "Quantity",
        },
        {
            accessorKey: 'brand',
            header: "Brand"
        },
        {
            accessorKey: 'category_name',
            header: "Category",
        },
        {
            accessorKey: 'price',
            header: "Price",
            Cell: (cell) => (
                <span>{formatTurkishLira.format(cell.row.original.price)}</span>
            )
        },
        {
            accessorKey: 'sale_amount',
            header: "Total Sale",
            Cell: (cell) => (
                <span>{formatTurkishLira.format(cell.row.original.price * cell.row.original.quantity)}</span>
            )
        }
    ];

    const influencersColumns = [
        {
            accessorKey: 'affiliate_company',
            header: "Influencer Name",
        },
        {
            accessorKey: 'total_sales',
            header: "Total Sales",
            Cell: (cell) => (
                <span>{formatTurkishLira.format(cell.row.original.total_sales)}</span>
            )
        },
        {
            accessorKey: 'total_quantity',
            header: "Total Quantity",
            Cell : (cell) => (
                <span>{cell.row.original.products.reduce((acc, item) => acc + item.quantity, 0)}</span>
            )

        }
    ];

    const brandsColumns = [
        {
            accessorKey: 'brand_name',
            header: "Brand Name",
        },
        {
            accessorKey: 'total_sales',
            header: "Total Sales",
            Cell: (cell) => (
                <span>{formatTurkishLira.format(cell.row.original.total_sales)}</span>
            )
        },
        {
            accessorKey: 'total_quantity',
            header: "Total Quantity",
            Cell : (cell) => (
                <span>{cell.row.original.products.reduce((acc, item) => acc + item.quantity, 0)}</span>
            )

        }
    ]


    const productsResultsTableColumns = {}
    productsResultsTableColumns.visibility = {}
    productsResultsTableColumns.data = productsColumns;

    const influencerResultsTableColumns = {}
    influencerResultsTableColumns.visibility = {}
    influencerResultsTableColumns.data = influencersColumns;

    const brandResultsTableColumns = {}
    brandResultsTableColumns.visibility = {}
    brandResultsTableColumns.data = brandsColumns;


    const handleGetReport = async (payload) => {
        setConversionResult([]);
        if (!payload.startDate || !payload.endDate) return;
        setPayload(payload);
        const response = await getConversionReport(payload);
        if (!!response.data) {
            setConversionResult(response.data);
        }
    };


    const handleDownloadFile = async (fileType) => {
        const startEndDateValue = {
            startDate: payload.startDate,
            endDate: payload.endDate
        }
        if (!startEndDateValue || !startEndDateValue.startDate || !startEndDateValue.endDate) return;
        const startDate = startEndDateValue.startDate;
        const endDate = startEndDateValue.endDate;
        switch (fileType) {
            case EXPORT_FILE_TYPES.excel:
                window.open(`${(process.env.REACT_APP_DASHBOARD_API ?? "http://localhost:4000") + DOWNLOAD_OFFER_PRODUCTS_EXCEL_DATA}?offerId=${payload.offerId}&startDate=${startDate}&endDate=${endDate}&reportTypeId=${payload.reportTypeId}&type=excel`, "_blank");
                break;
            case EXPORT_FILE_TYPES.csv:
                window.open(`${(process.env.REACT_APP_DASHBOARD_API ?? "http://localhost:4000") + DOWNLOAD_OFFER_PRODUCTS_EXCEL_DATA}?offerId=${payload.offerId}&startDate=${startDate}&endDate=${endDate}&reportTypeId=${payload.reportTypeId}&type=csv`, "_blank");
                break;
            default:
                break
        }
    }

    const expandProducts = (cell) => {
        return (
            <div>
                {cell.row.original.products && cell.row.original.products.length > 0 && <div>
                    <GenericTable tableData={cell.row.original.products}
                                  enableGlobalSearchBar={false}
                                  enableColumnFilters={false}
                                  enableFullScreenToggle={false}
                                  hideAllToolbar={true}
                                  headerBgColor={"#E74C3C"}
                                  outputColumns={resultColumns}></GenericTable>
                </div>}
            </div>
        )
    }


    return (
        <>
            <div className={styles.container}>
                <BreadcrumbsBar/>
                <Card title={t("menu-item.purchase_reports")}>
                    <ReportsFiltersUI
                        onGenerateButtonClicked={handleGetReport}
                        hideOfferSelectionDropdown={false}
                        hideReportTypeDropdown={false}/>

                    {
                        !!conversionResult && <div>

                            {(conversionResult.results && conversionResult.results.length > 0) &&

                                /*<ProductDevicePerformance results={conversionResult}/>*/
                                <ConversionsResultsUI reports={conversionResult}/>

                            }
                            <div className={styles.buttonHolder}>
                                <ExportDropdown handleCb={handleDownloadFile}/>
                            </div>
                            {!!payload && payload.reportTypeId === 0 &&
                                <GenericTable tableData={conversionResult.results}
                                              outputColumns={productsResultsTableColumns}></GenericTable>
                            }
                            {!!payload && payload.reportTypeId === 1 &&
                                <GenericTable tableData={conversionResult.results}
                                              expand={expandProducts}
                                              tableExpandPadding={0}
                                              outputColumns={influencerResultsTableColumns}></GenericTable>
                            }
                            {!!payload && payload.reportTypeId === 2 &&
                                <GenericTable tableData={conversionResult.results}
                                              outputColumns={brandResultsTableColumns}
                                              expand={expandProducts}
                                              tableExpandPadding={0}
                                ></GenericTable>
                            }
                        </div>
                    }
                </Card>
            </div>
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
            />
        </>
    )
}

export default ProductPerformance;
