import React from 'react';
import styles from './UploadConversionFormatExcel.module.scss';
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import Card from "../../common/card/Card";
import {Alert, Button, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {CheckOutlined, FileUploadOutlined, UploadFileOutlined} from "@mui/icons-material";
import {correctAndExportCSV} from "../../services/conversionExcelGenerationService";
import Toast from "../../common/toast/Toast";
import useToast from "../../common/hooks/useToast";
import {AiFillFileExcel} from "react-icons/ai";

const UploadConversionFormatExcel = () => {
    const [formData, setFormData] = React.useState(null);
    const {toastContent, isToastOpen, toggleToast, setToastContent} = useToast();
    const [commissionRate, setCommissionRate] = React.useState(0);
    const csvFileChanged = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        setFormData(formData);
    }

    const exportCsvToExcel = async () => {
        if (!formData || commissionRate === 0) {
            return;
        }
        formData.append('commissionRate', commissionRate);
        const response = await correctAndExportCSV(formData);
        if (!!response.data) {
            setToastContent("Data updated successfully");
        } else {
            setToastContent("Data update failed");
        }
        setFormData(null);
        toggleToast();
        //getPotentialInfluencers();
    }

    const addCommissionRateToFormData = (e) => {
        setCommissionRate(e.target.value);
    }

    return (
        <div className={styles.container}>
            <BreadcrumbsBar/>

            <Card title={"Upload Conversion Format CSV"} customIcon={<AiFillFileExcel/>}>
                <div className={styles.compContainer}>
                    <div className={styles.uploadExcelPart}>
                        <div>
                            <TextField
                                variant="outlined"
                                type="number"
                                size={"small"}
                                label={"UPLOAD SERVER-LOG CSV"}
                                sx={{width: "300px"}}
                                disabled={true}
                                value={"UPLOAD SERVER-LOG CSV"}
                                contentEditable={false}s
                                InputProps={{
                                    endAdornment: (
                                        <IconButton component="label">
                                            <FileUploadOutlined/>
                                            <input
                                                styles={{display: "none"}}
                                                type="file"
                                                accept=".csv"
                                                hidden
                                                onChange={csvFileChanged}
                                                name="[licenseFile]"
                                            />
                                        </IconButton>
                                    ),
                                }}
                            />
                            <div className={styles.alertMessage}>{formData && formData.get("file") &&
                                <Alert icon={<CheckOutlined fontSize="inherit"/>}
                                       severity="success">{formData.get("file").name}</Alert>}</div>
                        </div>

                        <TextField variant="outlined"
                                   type="text" size={"small"}
                                   value={commissionRate}
                                   label={"Commission Rate"}
                                   onChange={addCommissionRateToFormData}></TextField>
                    </div>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={exportCsvToExcel}
                        disabled={!Number(commissionRate) || !formData || commissionRate === 0}
                        startIcon={<UploadFileOutlined/>}>Upload</Button>
                </div>
            </Card>
            <Toast isOpen={isToastOpen} onClose={toggleToast} content={toastContent} severity="info"/>
        </div>
    );
};

export default UploadConversionFormatExcel;
