import React from 'react';

import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import {formatNumber, formatTurkishLira} from "../../common/utils";
import {EmojiEventsOutlined, InstallMobileOutlined, QrCodeOutlined} from "@mui/icons-material";
import {RiAppleLine} from "react-icons/ri";
import {FcAndroidOs} from "react-icons/fc";
import styles from "./ConversionsResultsUI.module.scss";

const ConversionsResultsUI = ({
                                  reports
                              }) => {
    return (
        <div className={styles.reportSummary}>
            {reports.totalSales !== 0 &&
                <div className={styles.boxHolder}>
                    <div className={styles.iconTitleHolder}>
                        <div className={styles.iconHolder}>
                            <LocalShippingOutlinedIcon sx={{
                                width: 20,
                                height: 15,
                            }}/>
                        </div>
                        <div className={styles.title}>Total Sales</div>
                    </div>
                    <div
                        className={styles.moneyFormat}>{formatTurkishLira.format(reports.totalSales)}</div>
                </div>}
            {reports.totalQuantity !== 0 &&
                <div className={styles.boxHolder}>
                    <div className={styles.iconTitleHolder}>
                        <div className={styles.iconHolder}>
                            <QrCodeOutlined sx={{
                                width: 20,
                                height: 15,
                            }}/>
                        </div>
                        <div className={styles.title}>Total Products Sold</div>
                    </div>
                    <div className={styles.moneyFormat}>{formatNumber(reports.totalQuantity)}</div>
                </div>}
              {reports.appInstallations !==  0 &&
                                <div className={styles.boxHolder}>
                                    <div className={styles.iconTitleHolder}>
                                        <div className={styles.iconHolder}>
                                            <InstallMobileOutlined sx={{
                                                width: 20,
                                                height: 15,
                                            }}/>
                                        </div>
                                        <div className={styles.title}>App Installs</div>
                                    </div>
                                    <div className={styles.moneyFormat}>{formatNumber(reports.appInstallations)}</div>
                                </div>}
            {reports.iosPurchases !== 0 &&
                <div className={styles.boxHolder}>
                    <div className={styles.iconTitleHolder}>
                        <div className={styles.iconHolder}>
                            <RiAppleLine sx={{
                                width: 20,
                                height: 15,
                            }}/>
                        </div>
                        <div className={styles.title}>iOS Purchases</div>
                    </div>
                    <div className={styles.moneyFormat}>{formatNumber(reports.iosPurchases)}</div>
                </div>}

            {reports.androidPurchases !== 0 &&
                <div className={styles.boxHolder}>
                    <div className={styles.iconTitleHolder}>
                        <div className={styles.iconHolder}>
                            <FcAndroidOs sx={{
                                width: 24,
                                height: 24,
                            }}/>
                        </div>
                        <div className={styles.title}>Android Purchases</div>
                    </div>
                    <div className={styles.moneyFormat}>{formatNumber(reports.androidPurchases)}</div>
                </div>}

            {reports.isFirstPurchase !== 0 && <div className={styles.boxHolder}>
                <div className={styles.iconTitleHolder}>
                    <div className={styles.iconHolder}>
                        <EmojiEventsOutlined sx={{
                            width: 20,
                            height: 15,
                        }}/>
                    </div>
                    <div className={styles.title}>First Purchase</div>
                </div>
                <div
                    className={styles.moneyFormat}>{formatTurkishLira.format(reports.firstPurchaseAmount)} - {formatNumber(reports.isFirstPurchase)} times
                </div>
            </div>}
        </div>
    );
};

export default ConversionsResultsUI;
