import {clientGetWrapper, clientPostWrapper} from "../api/Api";
import {GET_ALL_KARACA_USERS, GET_KARACE_PDF_URL, UPDATE_KARACA_USER_REVIEW_STATE} from "../common/constants/routes";


export const getKaracaKvkkFormUrl = () => {
    return clientPostWrapper(GET_KARACE_PDF_URL);

}

export const getAllKaracaUsers = () => {
    return clientGetWrapper(GET_ALL_KARACA_USERS);
}


export const updateKaracaUserReviewState = (payload) => {
    return clientPostWrapper(UPDATE_KARACA_USER_REVIEW_STATE,payload);
}



