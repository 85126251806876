import React, {useEffect, useState} from 'react';
import styles from './ReportsFiltersUI.module.scss';
import {Autocomplete, Button, TextField} from "@mui/material";
import {t} from "i18next";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import {FIRST_REPORT_DATE} from "../../../common/constants/constService";
import {DATE_TYPES, startEndDateInitial} from "../../../common/utils";
import {useSelector} from "react-redux";
import moment from "moment/moment";
import {dateFormatWithHours} from "../../../utils/common";

const offersHasBrands = [123, 132, 157, 99];
const offersHasInfluencers = [123, 132, 157];

let reportTypes = [];


const ReportsFiltersUI = ({
                              onGenerateButtonClicked,
                              hideReportTypeDropdown = true,
                              hideOfferSelectionDropdown = true
                          }) => {
    const offersList = useSelector((state) => state.offersList.offers);
    const userData = useSelector((state) => state.auth.user);
    const [dropdownReportTypes, setDropdownReportTypes] = useState(reportTypes);
    const [startEndDateValue, setStartEndDateValue] = React.useState(startEndDateInitial);
    const [selectedOfferId, setSelectedOfferId] = useState("");
    const [selectedReportTypeId, setSelectedReportTypeId] = useState(0);


    const [offers, setOffers] = useState([]);

    useEffect(() => {
        reportTypes = [
            {
                id: 0,
                value: "Product Performance"
            }
        ]
        if (!!offersList && offersList.length > 0) {


            if (userData.isMasterPassword) {
                reportTypes = [
                    {
                        id: 0,
                        value: "Product Performance"
                    },
                    {
                        id: 1,
                        value: "Influencers Performance"
                    },
                    {
                        id: 2,
                        value: "Brands Performance"
                    }
                ]
                setOffers(offersList);
            } else {
                const selectedOffers = userData.user.offer_id_list;
                let offerObjects = [];

                if (selectedOffers) {
                    if (selectedOffers.includes(",")) {
                        const offerList = selectedOffers.split(",");
                        offerList.forEach(id => {
                            const offer = offersList.find(offer => offer.offerId === Number(id));
                            if (!!offer) offerObjects.push(offer);
                        })
                    } else {
                        const offer = offersList.find(offer => offer.offerId === Number(selectedOffers));
                        offerObjects.push(offer);
                    }
                    setOffers(offerObjects);

                    const selectedOfferIds = offerObjects.map(offer => offer.offerId);

                    selectedOfferIds.forEach(offerId => {
                        const isOfferIdExistOnOfferHasBrands = offersHasBrands.find(offer => offer === offerId);
                        if (!!isOfferIdExistOnOfferHasBrands) {
                            reportTypes.push(
                                {
                                    id: 2,
                                    value: "Brands Performance"
                                }
                            )
                        }
                        const isOfferIdExistOnOfferHasInfluencers = offersHasInfluencers.find(offer => offer === offerId);
                        if (!!isOfferIdExistOnOfferHasInfluencers) {
                            reportTypes.push(
                                {
                                    id: 1,
                                    value: "Influencers Performance"
                                }
                            )
                        }
                    })
                }

            }
        }
        setDropdownReportTypes(reportTypes);
    }, [offersList]);// eslint-disable-line react-hooks/exhaustive-deps

    const isButtonDisabled = () => (!startEndDateValue.endDate ||
        !startEndDateValue.startDate ||
        (hideOfferSelectionDropdown ? false : !selectedOfferId));
    const getOfferValue = () => {
        const selected = selectedOfferId ? offers.find(offer => offer.offerId === selectedOfferId) : null;
        return (!!selected) ? selected.id + ' - ' + selected.name : "Select Offer";

    };

    const handleReportTypeDropdownChange = (e) => {
        const {textContent} = e.target;
        if (!textContent) return;
        setSelectedReportTypeId(Number(dropdownReportTypes.find(reportType => reportType.value === textContent).id))
    };

    const handleStartDateChange = (newValue, type) => {
        if (!newValue) return;
        if (type === DATE_TYPES.START_DATE) {
            setStartEndDateValue(prevState => ({
                ...prevState,
                startDate: newValue
            }));
        } else {
            setStartEndDateValue(prevState => ({
                ...prevState,
                endDate: newValue
            }));
        }
    };

    const handleOfferDropdownChange = async (e) => {
        const {textContent} = e.target;
        if (!textContent) return;
        setSelectedOfferId(Number(textContent.split(" - ")[0]));
    }


    const handleGetReport = async () => {

        if (!startEndDateValue || !startEndDateValue.startDate || !startEndDateValue.endDate) return;
        const startDate = moment(startEndDateValue.startDate.$d)
            .set({hour: 0, minute: 0, second: 0}).format(dateFormatWithHours);
        const endDate = moment(startEndDateValue.endDate.$d)
            .set({hour: 23, minute: 59, second: 59}).format(dateFormatWithHours);
        const payload = {
            reportTypeId: selectedReportTypeId,
            offerId: selectedOfferId,
            startDate: startDate,
            endDate: endDate
        }
        onGenerateButtonClicked(payload);

    };


    return (
        <div className={styles.uploaderContainer}>
            {!!offers && offers.length > 0 && <div className={styles.filterBlock}>
                {!hideReportTypeDropdown && <Autocomplete
                    freeSolo
                    name="Report Types"
                    required
                    size="small"
                    value={dropdownReportTypes.find(reportType => reportType.id === selectedReportTypeId)?.value}
                    options={dropdownReportTypes.map(reportType => reportType.value)}
                    onChange={e => handleReportTypeDropdownChange(e)}
                    sx={{width: 220}}
                    renderInput={(params) => <TextField {...params}
                                                        label={t('create-new-user.select-report-type')}/>}
                />}

                {!hideOfferSelectionDropdown && <Autocomplete
                    name="Offers"
                    freeSolo
                    required
                    size="small"
                    value={getOfferValue()}
                    options={offers.map(offer => offer.offerId + " - " + offer.name)}
                    onChange={handleOfferDropdownChange}
                    sx={{width: 220}}
                    renderInput={(params) => <TextField {...params}
                                                        label={t('create-new-user.select-offers')}/>}
                />}
            </div>}
            <div className={`${styles.filterBlock} ${styles.datePickers}`}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker label="Start Date"
                                    minDate={dayjs(FIRST_REPORT_DATE)}
                                    defaultValue={dayjs(new Date())}
                                    slotProps={{ textField: { size: 'small' } }}
                                    onChange={(newValue) =>
                                        handleStartDateChange(newValue, DATE_TYPES.START_DATE)}/>
                        <DatePicker label="End Date"
                                    disableFuture
                                    slotProps={{ textField: { size: 'small' } }}
                                    minDate={dayjs(startEndDateValue.startDate)}
                                    onChange={(newValue) =>
                                        handleStartDateChange(newValue, DATE_TYPES.END_DATE)}/>
                    </DemoContainer>
                </LocalizationProvider>
            </div>
            <Button
                disabled={isButtonDisabled()}
                variant="contained"
                color="confirmButton"
                sx={{
                    fontWeight: 700,
                    fontSize: 13
                }}
                size="small"
                onClick={handleGetReport}>GO!</Button>
        </div>
    );
};

export default ReportsFiltersUI;
