import React, {useEffect, useState} from 'react';
import Card from "../../common/card/Card";
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import {t} from "i18next";
import GenericTable from "../../common/table/GenericTable";
import dayjs from "dayjs";
import {Button, Chip} from "@mui/material";
import {BlockOutlined, DoneOutlined, LaunchOutlined, LocalDiningOutlined} from "@mui/icons-material";
import styles from "./KaracaNewPartners.module.scss";
import Toast from "../../common/toast/Toast";
import useToast from "../../common/hooks/useToast";
import {DOWNLOAD_KARACA_USERS_CSV} from "../../common/constants/routes";
import {getInstagramLink} from "../../common/utils";
import ExportDropdown from "../../common/exportDropdown/ExportDropdown";
import {EXPORT_FILE_TYPES} from "../AdvertiserManagement/CreateEditPaidProject/CreateEditPaidProject";
import {getAllKaracaUsers, updateKaracaUserReviewState} from "../../services/karacaUsersService";

const KaracaNewPartners = () => {
    const {isToastOpen, toggleToast, toastContent, setToastContent} = useToast();
    const [tableData, setTableData] = useState([]);

    const getAllKaracaUsersPromise = async () => {
        const response = await getAllKaracaUsers();
        if (!!response.data && !!response.data.users) {
            setTableData(response.data.users);
        } else {
            setToastContent(t("common-server-err-msg"));
            toggleToast();
        }
    }

    useEffect(() => {
        getAllKaracaUsersPromise();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const karacaUsersColumns = {};
    karacaUsersColumns.visibility = {}
    const handleUpdateKaracaUser = async (original) => {
        const {is_reviewed, user_id} = original;
        const response = await updateKaracaUserReviewState({
            is_reviewed: !is_reviewed,
            user_id
        })
        let selectedUser = tableData.find(user => user.user_id === user_id);
        if (!!selectedUser) {
            selectedUser.is_reviewed = !is_reviewed;
        }
        setToastContent(!!response.data.isUpdated ?
            t("lcw-new-users.is-reviewed-state-changed-success-msg") :
            t("common-server-err-msg"));
        toggleToast();
    };
    karacaUsersColumns.data = [
        {
            accessorKey: 'is_reviewed',
            header: "Is reviewed?",
            Cell: (cell) => (
                <div className={styles.updateCell}>
                    <Button
                        variant="contained"
                        size={"small"}
                        color={cell.row.original.is_reviewed ? "success" : "error"}
                        startIcon={cell.row.original.is_reviewed ? <DoneOutlined/> : <BlockOutlined/>}
                        onClick={(e) => {
                            handleUpdateKaracaUser(cell.row.original);
                        }}>
                        {cell.row.original.is_reviewed ? "YES" : "NO"}
                    </Button>
                </div>
            )
        },
        {
            accessorKey: 'user_id',
            header: "User Id",
        },
        {
            accessorKey: 'firstname',
            header: "Name & Surname",
            Cell: (cell) => (
                <div>{cell.row.original.firstname} {cell.row.original.lastname}</div>
            )
        },
        {
            accessorKey: 'email',
            header: t("common.email"),
        },
        {
            accessorKey: 'phone',
            header: "Phone",
        },
        {
            accessorKey: 'instagram_account',
            header: "Instagram Account",
            Cell: (cell) => (
                <div>
                    {!!cell.row.original.instagram_account && <div className={styles.socialHolder}>
                        <div>{cell.row.original.instagram_account}</div>
                        <Button
                            variant="contained"
                            sx={{mT: 1, width: "fit-content"}}
                            startIcon={<LaunchOutlined/>}
                            size={"small"}
                            onClick={(e) => {
                                let username = getInstagramLink(cell.row.original.instagram_account);
                                username = (username[0] === "/") ?
                                    username.substring(1) :
                                    username;
                                window.open(`https://www.instagram.com/${username}`, '_blank');
                            }}>{t("common.open")}
                        </Button>
                    </div>}
                </div>
            )
        },
        {
            accessorKey: 'follower_count',
            header: "Follower Count",
        },
        {
            accessorKey: 'tiktok_account',
            header: "Tiktok Account",
        },
        {
            accessorKey: 'x_account',
            header: "X Account",
        },
        {
            accessorKey: 'categories',
            header: "Categories",
            size: 600,
            Cell: (cell) => (
                <div>
                    {!!cell.row.original.categories &&
                        <div className={styles.tagsHolder}>
                            {JSON.parse(cell.row.original.categories).map(category => (
                                <div key={category}><Chip color="primary" sx={{fontWeight: 700}}
                                                          label={category}></Chip></div>
                            ))}
                        </div>
                    }
                </div>
            )
        },
        {
            accessorKey: 'created_at',
            header: "Created At",
            Cell: (cell) => (
                <div>
                    {dayjs(cell.row.original.created_at, "YYYY-MM-DD+h:mm").format("YYYY-MM-DD h:mm")}
                </div>
            )
        }
    ];


    const handleDownloadFile = async (fileType) => {
        switch (fileType) {
            case EXPORT_FILE_TYPES.excel:
                window.open((process.env.REACT_APP_DASHBOARD_API ?? "http://localhost:4000") + DOWNLOAD_KARACA_USERS_CSV + "?type=excel", "_blank");
                break;
            case EXPORT_FILE_TYPES.csv:
                window.open((process.env.REACT_APP_DASHBOARD_API ?? "http://localhost:4000") + DOWNLOAD_KARACA_USERS_CSV + "?type=csv", "_blank");
                break;
            default:
                break;

        }
    }

    return (
        <div className={styles.container}>
            <BreadcrumbsBar/>
            <Card title="Karaca Nano Partners" customIcon={<LocalDiningOutlined/>}>
                {tableData.length > 0 &&
                    <div>
                        <div className={styles.buttonHolder}>
                            <ExportDropdown handleCb={handleDownloadFile}/>
                        </div>
                        <GenericTable
                            outputColumns={karacaUsersColumns}
                            tableData={tableData}
                            allRowsAreExpanded={tableData.length === 1}
                            rowCount={tableData.length}
                            enablePagination={true}
                        />
                    </div>}
            </Card>
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
                severity="info"
            />
        </div>
    );
};

export default KaracaNewPartners;
