import {clientPostWrapper} from "../api/Api";
import {GET_ALL_BRANDS_INFLUENCERS_CONVERSIONS} from "../common/constants/routes";

export const getBrandsInfluencersReports = async (payload) => {
    try {
        return await clientPostWrapper(GET_ALL_BRANDS_INFLUENCERS_CONVERSIONS, payload);
    } catch (e) {
        console.log(e)
        return e;
    }

}